

.active-press{
  background-color: #136bcfbd; 
}


html{
  background-image: url('../img/image\ 72.png');
  background-size: cover;
  background-repeat: no-repeat;
}


.app-container {
  display: flex;
  flex-direction: column;
  height: auto;

}


.navbar {
  background-color: #014BA0; 
  width: 100%;
  height: 60px;
  opacity: 0.9;
}


.nav-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  list-style-type: none;
}


.nav-item {
  padding: 3px 12px;
}


.nav-link {
  text-decoration: none;
  color: white;
  border-radius: 5px;
  padding: 10px;
  transition: background-color 0.3s ease; 
}



.nav-link:hover {
  background-color: #136bcfbd; 
}


.content {
  flex: 1;
  padding: 5px;
}


.title {
  color: white;
}