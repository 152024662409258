

html {
  background-image: url('../img/image\ 72.png');
  background-size: cover;
  background-repeat: no-repeat;
}


.app-container {
  display: flex;
  flex-direction: column;
  height: auto;
}

.configuracao-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.info-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  width: 600px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.9); 
  border: 1px solid rgba(255, 255, 255, 0.2); 
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s ease-in-out; 
}

.info-section:hover {
  transform: scale(1.05); 
}

.section-title {
  font-size: 24px;
  color: #FFFFFF; 
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: 10px;
}

.section-content {
  font-size: 18px;
  max-width: 50ch;
  color: #FFFFFF; 
  line-height: 1.5;
  margin-top: 10px;
}

.section-content a {
  color: #2174D4;
  text-decoration: none;
  transition: color 0.3s ease-in-out; 
}

.section-content a:hover {
  color: #FFFFFF; 
}

.ia-drive {
  width: 45ch;
}


@media (max-width: 630px) {
  .info-section {
    width: 450px;
  }
}

@media (max-width: 470px) {
  .info-section {
    width: 350px;
    height: auto;
  }

  .section-content {
    font-size: 16px;
    max-width: 30ch;
  }
}
